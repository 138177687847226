var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-head"},[_c('div',{staticClass:"head-right",on:{"click":_vm.home}},[_c('img',{attrs:{"src":require("@/assets/img/hlwlogo.png"),"alt":"logo"}})]),_c('div',{staticClass:"home-nav",class:[_vm.isLogin ? 'login-home-nav' : 'noLogin-home-nav']},[_c('div',{staticClass:"home-nav-list"},[_vm._l((_vm.navigations),function(el,index){return [_c('router-link',{key:index,attrs:{"exact":index == 0,"to":el.navigationPath,"active-class":"active","custom":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [(el.isOpen)?_c('div',{on:{"click":function($event){return _vm.openPage(el.navigationPath)}}},[_vm._v(" "+_vm._s(el.navigationName)+" ")]):_c('div',{class:[isActive && 'active'],on:{"click":navigate}},[_vm._v(" "+_vm._s(el.navigationName)+" ")])]}}],null,true)})]}),_c('div',{on:{"click":_vm.toBuy}},[_vm._v("购买")])],2)]),_c('div',{staticClass:"home-login"},[_c('a-dropdown',{attrs:{"trigger":['click'],"placement":"bottomCenter","getPopupContainer":function (triggerNode) {
          return triggerNode.parentNode;
        }}},[_c('span',{staticStyle:{"line-height":"52px"},on:{"click":function (e) { return e.preventDefault(); }}},[_vm._v("专有业务平台"),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},_vm._l((_vm.urlList),function(el){return _c('a-menu-item',{key:el.id,on:{"click":function($event){return _vm.selectData(el.url)}}},[_vm._v(" "+_vm._s(el.name)+" ")])}),1)],1),(!_vm.userInfo)?[_c('span',{on:{"click":function($event){return _vm.$router.push('/login')}}},[_vm._v("登录")]),_c('p',{on:{"click":function($event){return _vm.$router.push('/register')}}},[_vm._v("立即注册")])]:[_c('span',{on:{"click":_vm.userCenter}},[_vm._v("控制台")]),_c('a-dropdown',{attrs:{"trigger":['click'],"overlay-class-name":"dropdown-user-info","placement":"bottomRight"}},[_c('div',{staticClass:"layout-header-user-info",on:{"click":function (e) { return e.preventDefault(); }}},[_c('span',[_c('v-img',{attrs:{"attachment-id":_vm.userInfo.profilePictureAttmId,"default-img":require('@/assets/img/defaultavatar.png')}})],1),_c('a-icon',{attrs:{"type":"caret-down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},slot:"overlay"},[_c('a-menu-item',[_c('p',{staticClass:"layout-header-user-btn",on:{"click":_vm.userInfoBtn}},[_vm._v(" 基本信息 ")])]),_c('a-menu-item',[_c('p',{staticClass:"layout-header-user-btn",on:{"click":_vm.setUp}},[_vm._v("安全设置")])]),_c('a-menu-item',[_c('p',{staticClass:"layout-header-user-btn quit-login",on:{"click":function($event){return _vm.$router.push('/logout')}}},[_vm._v(" 退出 ")])])],1)],1)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }